import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import {
  checkPending,
  getFeaturesList,
  isAdmin,
  isAuthenticated,
  isPAH,
  login,
  role,
} from "../../redux/auth/index";

import AudioPlayer from "../core/ReactAudioPlayer";
import SendInvitation from "../core/SendInvitationModal";
import LeftMenu from "../layout/LeftMenu";

import AccountSetup from "../../pages/auth/AccountSetup";
import EmailVerificationError from "../../pages/auth/EmailVerificationError";
import EmailVerified from "../../pages/auth/EmailVerified";
import ResetPassword from "../../pages/auth/ResetPassword";
import Contact from "../../pages/Contact";
import PageNotFound from "../../pages/PageNotFound";
import LoginNotFound from "../../pages/LoginNotFound";
import SWMSGenerated from "../../pages/pah/swms/SWMSGenerated";
import PurchaseCancel from "../../pages/purchase/PurchaseCancel";
import PurchaseCompleted from "../../pages/purchase/PurchaseCompleted";
import * as Sentry from "@sentry/react";

import {
  adminSWMSRoutes,
  authRoutes,
  commonRoutes,
  pahRoutes,
  staffAccountRoutes,
  withoutCompanyRoute,
} from "../../../routes";
import ReqCompanyModal from "../../pages/purchase/ReqCompanyModal";
import { ErrorBoundary } from "react-error-boundary";
import FallBackUI from "pages/FallBackUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faQuestion, faXmark } from "@fortawesome/free-solid-svg-icons";
import { openInNewTab } from "utils/CommonUtils";
import { HeaderSvgs } from "utils/IconUtils";
import auth from "services/auth";
import { GAEvents } from "utils/GoogleAnalytics";
import { getAuditTemplate } from "reduxStore/user";
import AdminTools from "pages/pah/AdminTools";

interface LayoutProps {
  children?: any;
}

const Layout = (props: LayoutProps) => {
  const [invitationPopup, setInvitationPopup] = useState(false);
  const [showCompanyDetailModal, setShowCompanyDetailModal] = useState(false);

  const authenticated = useSelector(isAuthenticated);
  const isPending = useSelector(checkPending);
  const eventTracker = new GAEvents();

  const alertObj = useAlert();

  const userRole = useSelector(role);
  const userIsAdmin = useSelector(isAdmin);
  const isPah = useSelector(isPAH);
  const features = useSelector(getFeaturesList);
  const auditResponseTemplate = useSelector(getAuditTemplate);

  let location = useLocation();

  const notAccessiblePaths = [
    "/home",
    "/home/getting-started",
    "/home/notification-employee/add",
    "/home/contractor",
    "/home/contractor/connect",
    "/home/job",
    "/home/job/first-job",
    "/home/employee",
    "/home/employee/setup",
    "/home/employee/add",
    "/admin",
    "/documents",
    "/schedule",
    "/audits",
    "/audits/create",
    "/audits/manage",
    "/audits/review",
    "/audits/web-link",
    "/audits/web-link/",
    "/gantt",
    "/quote-tool",
    "/invoice",
    "/invoice/generate",
    "/data-management",
    "/induction",
    "/view-swms",
    "/pssc",
    "/pst",
    "/record-purchases",
    "/progress-reports",
    "/incident-reports",
    "/jsa-reports",
    "/swms",
    "/swms/select-job",
    "/swms/section-one",
    "/swms/pc-details",
    "/swms/section-details",
    "/swms/contractor-detail",
    "/swms/section-three",
    "/swms/choose-trade",
    "/swms/view-activities",
    "/swms/swms-template",
    "/swms/work-activities",
    "/swms/work-activities-customize",
    "/swms/links",
    "/swms/generate-swms",
    "/swms/generated",
    "/staff/employee",
    "/staff/employee/setup",
    "/staff/employee/add",
    "/staff/contractors",
    "/staff/contractors/manage",
    "/staff/contractors/connect",
    "/staff/contacts",
    "/staff/contacts/add",
    "/worksite",
    "/worksite/manage",
    "/worksite/review-contracts",
    "/worksite/list",
    "/worksite/create",
    "/variation",
    "/variation/create",
    "/defects",
    "/defects/create",
    "/defects/create",
    "/work-request/accept/",
    "/work-request/decline/",
    "/notification",
    "/notification/dashboard",
    "/cart",
    "/help",
    "/help/user-guide",
    "/help/faqs",
    "/user",
    "/user/details",
    "/user/manage",
    "/user/account",
    "/user/share-company-details",
    "/user/manage-features",
    "/manage-features",
    "/admin-swms",
    "/admin-swms/view-wa",
    "/admin-swms/scope/bulk-add",
    "/admin-swms/scope/bulk-add/existing-scope",
    "/admin-swms/scope/bulk-add-wa",
    "/admin-swms/custom-trade",
    "/my-jobs",
  ];

  const currentPath = window.location.pathname;
  const isRestricted = notAccessiblePaths.some((path) =>
    currentPath.startsWith(path)
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const staffType = ["SAH", "NAH", "SAH_LH"];

  const showCompanyBranding =
    auditResponseTemplate &&
    auditResponseTemplate.brandingVisible &&
    location.pathname.includes("/audits/link");

  useEffect(() => {
    window["alertContainer"] = alertObj;
  }, [alertObj]);

  useEffect(() => {
    // eventTracker.trackPageView(window.location.pathname);
    const auth = localStorage.getItem("_auth");

    if (auth) {
      dispatch(
        login({
          auth: JSON.parse(auth),
          save: false,
        })
      );
    }
  }, [authenticated]);

  const focusToMainContent = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    const container = document.getElementById("main-content");
    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute("tabindex"), 1000);
    }
  };

  function closeImageView() {
    document.getElementById("image-view")!.style.display = "none";
    document.getElementById("image-view-overlay")!.style.display = "none";
    document.body.classList.remove("stop-scroll-body");
    var modalImg = document.getElementById("image-view-content");
    if (modalImg != null) {
      (modalImg as HTMLImageElement).src = "";
    }
  }
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <div>
      <div className="page-content-wrapper">
        <AudioPlayer />
        <SendInvitation
          isVisible={invitationPopup}
          onClose={() => {
            setInvitationPopup(false);
          }}
        />
        <ReqCompanyModal
          onClickLeftMenu={true}
          isVisible={showCompanyDetailModal}
          onClose={() => {
            setShowCompanyDetailModal(false);
          }}
        />

        <div
          className={`${
            location.pathname.includes("view-wa") ? "" : "container-fluid"
          } p-0`}
        >
          <div className="d-flex">
            {!location.pathname.includes("view-wa") && !showCompanyBranding && (
              <>
                <header className="p-0 side-menu">
                  <div
                    className="left-section close"
                    onClick={(e) => {
                      const menu =
                        document.getElementsByClassName("left-section");
                      if (menu.length > 0) {
                        menu[0].classList.toggle("close");
                        document.body.classList.toggle("body-hidden");
                      }
                    }}
                  >
                    <Link
                      onClick={(e) => e.stopPropagation()}
                      className="d-block"
                      style={{ backgroundColor: "#b8b8b8", cursor: "auto" }}
                      to="/"
                    >
                      <img
                        src="/assets/images/new_design/insite_logo_black.png"
                        alt="home"
                      />
                    </Link>
                    <LeftMenu
                      setShowCompanyDetailModal={setShowCompanyDetailModal}
                      path={""}
                      role={userRole}
                    />
                  </div>
                </header>
              </>
            )}
            <div className={`main-window bgwhite flex-grow-1`}>
              <div className="d-flex flex-column h-100">
                <div className="g-0 row top-header border-bottom border-secondary">
                  {showCompanyBranding ? (
                    <div className="d-flex px-3 py-2 bg px-md-5 align-items-center gap-3">
                      <img
                        src={
                          auditResponseTemplate.company.companyLogo.inlineUrl
                        }
                        alt="home"
                        height={40}
                      />
                      <div className="text-white">
                        {auditResponseTemplate.company.company_name}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-2">
                        <div
                          role="button"
                          className="hamburger-div align-items-center cursor-pointer"
                          onClick={() => {
                            const menu =
                              document.getElementsByClassName("left-section");
                            if (menu.length > 0) {
                              menu[0].classList.toggle("close");
                              document.body.classList.toggle("body-hidden");
                            }
                          }}
                          id="hamburger"
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faBars}
                              className="fa"
                              color="#000"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="align-self-center d-flex col-10 pe-3 gap-2 text-end py-2">
                        <div className="flex-grow-1 d-flex">
                          <Link
                            onClick={(e) => {
                              focusToMainContent(e);
                            }}
                            tabIndex={1}
                            to="#main-content"
                            className="skip-link text-white border py-1 fw-bold fs-6 px-3"
                          >
                            Skip to main content
                          </Link>
                        </div>
                        <button
                          className="btn invitation d-flex gap-3 align-items-center"
                          style={{
                            borderRadius: "0.5rem",
                          }}
                          tabIndex={0}
                          onClick={() => {
                            if (authenticated) {
                              setInvitationPopup(true);
                            } else {
                              navigate("/pricing");
                            }
                          }}
                        >
                          <label className="fw-bold work-sans">
                            {authenticated ? "Send Invite" : "Join insite.work"}
                          </label>
                          <HeaderSvgs.HeaderEmail fill="#fff" />
                        </button>
                        {authenticated && (
                          <>
                            <button
                              tabIndex={0}
                              className="btn invitation"
                              onClick={() => {
                                openInNewTab(
                                  `${window.location.origin}/help/faqs`
                                );
                              }}
                              style={{
                                borderRadius: "0.5rem",
                              }}
                              title="faqs"
                            >
                              <HeaderSvgs.HeaderQuestionMark fill="#fff" />
                            </button>
                            <button
                              tabIndex={0}
                              className="btn invitation"
                              onClick={() => {
                                openInNewTab(
                                  `${window.location.origin}/schedule`
                                );
                              }}
                              style={{
                                borderRadius: "0.5rem",
                              }}
                              title="faqs"
                            >
                              <HeaderSvgs.HeaderCalendar fill="#fff" />
                            </button>
                            <button
                              tabIndex={0}
                              className="btn invitation"
                              onClick={() => {
                                openInNewTab(`${window.location.origin}/user`);
                              }}
                              style={{
                                borderRadius: "0.5rem",
                              }}
                              title="faqs"
                            >
                              <HeaderSvgs.HeaderGear fill="#fff" />
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <main id="main-content" className="overflow-hidden">
                  <ErrorBoundary
                    FallbackComponent={FallBackUI}
                    onReset={() => {}}
                    resetKeys={[location]}
                  >
                    <SentryRoutes>
                      {commonRoutes}
                      {!authenticated && authRoutes}
                      {authenticated &&
                        isPah &&
                        !isPending &&
                        pahRoutes(features || [])}
                      {authenticated &&
                        isPah &&
                        isPending &&
                        withoutCompanyRoute}
                      {authenticated &&
                        staffType.includes(userRole.toUpperCase()) &&
                        staffAccountRoutes}
                      {userIsAdmin && adminSWMSRoutes}
                      {userIsAdmin && (
                        <Route path="admin-tools" element={<AdminTools />} />
                      )}
                      <Route
                        path="purchase-complete"
                        element={<PurchaseCompleted />}
                      />
                      <Route
                        path="purchase-cancel"
                        element={<PurchaseCancel />}
                      />
                      <Route
                        path="email-confirmed"
                        element={<EmailVerified />}
                      />
                      <Route path="contact" element={<Contact />} />
                      <Route
                        path="reset-password/:user_id"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="signup/setup/:user_id"
                        element={<AccountSetup />}
                      />
                      <Route
                        path="email-activation-failed"
                        element={<EmailVerificationError />}
                      />
                      <Route path="swms/sign/:id" element={<SWMSGenerated />} />

                      {!authenticated && isRestricted && (
                        <Route path={currentPath} element={<LoginNotFound />} />
                      )}
                      <Route path="*" element={<PageNotFound />} />
                    </SentryRoutes>
                  </ErrorBoundary>
                </main>
                <div className="d-flex px-3 py-3 gap-2 flex-wrap justify-content-between align-items-center bottom-footer border-top border-secondary">
                  <div className="d-flex flex-wrap gap-3 justify-content-between">
                    <a
                      href="#"
                      onClick={() => {
                        navigate("/privacy-policy");
                      }}
                      className="text-white pointer white-space"
                    >
                      Privacy Policy
                    </a>
                    <a
                      href="#"
                      className="text-white pointer white-space"
                      onClick={() => {
                        navigate("/terms");
                      }}
                    >
                      Terms & Conditions
                    </a>
                  </div>
                  {showCompanyBranding ? (
                    <Link
                      to={authenticated ? "/" : "/auth/login"}
                      className="d-flex gap-3 align-items-center text-decoration-none"
                    >
                      <span className="fs-6 text-white">Powered by</span>
                      <img
                        className="pe-2"
                        src="/assets/images/new_design/white_logo.png"
                        style={{ width: "6rem" }}
                        alt="insite.work"
                      />
                    </Link>
                  ) : (
                    <span className="text-white m-0">
                      &copy; insite.work Pty Ltd 2021 AU Pat Appln No 2021107297
                    </span>
                  )}
                </div>
              </div>
              {/* Footer */}
            </div>
            <div
              id="image-view-overlay"
              className="image-view-overlay"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                closeImageView();
              }}
            ></div>
            <div id="image-view" className="image-view">
              <div
                style={{ cursor: "pointer" }}
                className="image-view-close"
                onClick={(e) => {
                  e.preventDefault();
                  closeImageView();
                }}
              >
                <FontAwesomeIcon
                  id="image-view-close"
                  icon={faXmark}
                  style={{ height: "25px", width: "25px" }}
                />
              </div>
              <img
                id="image-view-content"
                className="image-view-content"
                alt="close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
