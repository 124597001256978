import React from "react";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../utils/UseTitleUtils";

const LoginNotFound = () => {
  const navigate = useNavigate();
  useTitle("401");
  return (
    <div className="content-section my-5 py-5 text-center">
      <h1 className="h1-text font-color-black mb-0">401</h1>
      <div className="four_zero_four_bg"></div>
      <div className="text-center">
        <span className="d-block h2-text">You’re not logged in</span>
        <span className="d-block">
          You have not access to this page, Please login!
        </span>
      </div>
      <div className="justify-content-center row mt-3">
        <div className="col-12 col-md-4">
          <button
            title="login"
            aria-label="Login"
            className="btn primary-btn w-100"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginNotFound;
